class Route_paths {
    paths = [
        { id: '0', path: '/' },
        { id: '1', path: '/authorization' },
        { id: '2', path: '/registration' },
        { id: '3', path: '/account' },
        { id: '4', path: '/im' },
        { id: '5', path: '/forgotpassword' }
    ]
}
export default Route_paths
