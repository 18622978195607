class Validates {
    checkLogin = (login: string): string | boolean => {
        if (login.length < 3) return 'Логин меньше 3-ёх символов'
        if (login.length > 15) return 'Логин больше 15-ти символов'
        else return true
    }

    checkPassword = (password: string): string | boolean => {
        if (password.length < 3) return 'Пароль меньше 3-ёх символов'
        if (password.length > 15) return 'Пароль больше 15-ти символов'
        else return true
    }

    checkEmail = (email: string): string | boolean => {
        if (email.length < 3) return 'Email меньше 3-ёх символов'
        if (email.length > 30) return 'Email больше 15-ти символов'
        else return true
    }
}
export default Validates
