import React, { ReactElement } from 'react'

import avatar_user from '../../images/help/message/user.png'

type Props = {
    onContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void
    id: string
    user: {
        name: string
        avatar?: string
    }
    message?: {
        text: string
        date: string
    }
    toOrFrom: boolean
    images?: {
        image: string
        alt?: string
    }[]
}

const Message = (props: Props) => {
    const img: ReactElement = (
        <div className={`${props.images ? 'mt-1' : 'my-auto'} w-fit`}>
            <img
                className='size-[50px] rounded-full'
                src={props.user.avatar ? props.user.avatar : avatar_user}
                alt='avatar'
            />
        </div>
    )
    return (
        <div className={`flex p-2 ${props.toOrFrom ? 'flex justify-end' : ''}`}>
            {!props.toOrFrom ? img : null}
            <div id='parent2' onContextMenu={props.onContextMenu}>
                {props.message && (
                    <div className='flex justify-between'>
                        {props.toOrFrom && <div></div>}
                        <div
                            id='parent'
                            // eslint-disable-next-line tailwindcss/no-custom-classname
                            className={`parent w-fit min-w-fit max-w-xl rounded-xl ${!props.toOrFrom ? 'ml-3 bg-zinc-100 text-black' : 'mr-3 bg-teal-600'} px-3 py-[0.35rem] text-left`}>
                            <p id={props.id}>{props.message.text}</p>
                            <div
                                className={`text-right text-xs font-normal ${!props.toOrFrom ? 'text-gray-900' : 'text-slate-300'}`}>
                                {props.message.date}
                            </div>
                        </div>
                        {!props.toOrFrom && <div></div>}
                    </div>
                )}
                {props.images && props.images?.length === 1 && (
                    <div>
                        <img
                            className={`${!props.toOrFrom ? 'ml-3' : 'mr-3'} m-1 w-[400px] rounded-md`}
                            src={props.images[0].image}
                            alt={props.images[0].alt}
                        />
                    </div>
                )}
                <div className={`${!props.toOrFrom ? 'ml-3' : 'mr-3'} grid grid-cols-3`}>
                    {props.images &&
                        props.images?.length > 1 &&
                        props.images.map((element, index) => (
                            <div key={index}>
                                <img className='m-1 w-[70px] rounded-md' src={element.image} alt={element.alt} />
                            </div>
                        ))}
                </div>
            </div>
            {!props.toOrFrom ? null : img}
        </div>
    )
}

export default Message
