import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'

interface AlertProps {
    text: string
    type: 'error' | 'info' | 'alert' | 'success'
}

interface AlertContextType {
    showAlert: (alert: AlertProps) => void
}

const AlertContext = createContext<AlertContextType | undefined>(undefined)

export const AlertProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [alerts, setAlerts] = useState<{ alert: AlertProps; startTime: number }[]>([])
    // HACK: Время для отображения уведомлений
    const timeForHideAlert = 3000
    useEffect(() => {
        const interval = setInterval(() => {
            setAlerts((prevAlerts) =>
                prevAlerts.reduce(
                    (acc, item) => {
                        const elapsed = Date.now() - item.startTime
                        if (elapsed < timeForHideAlert) {
                            acc.push(item)
                        }
                        return acc
                    },
                    [] as { alert: AlertProps; startTime: number }[]
                )
            )
        }, 20)

        return () => clearInterval(interval)
    }, [alerts])

    const showAlert = (alert: AlertProps) => {
        setAlerts((prevAlerts) => [...prevAlerts, { alert, startTime: Date.now() }])
    }

    return (
        <AlertContext.Provider value={{ showAlert }}>
            <div className='absolute right-0 top-24 mr-2 min-w-60'>
                {alerts.map((item, index) => {
                    const elapsed = Date.now() - item.startTime
                    const remaining = timeForHideAlert - elapsed
                    const width = remaining > 0 ? `${(remaining / timeForHideAlert) * 100}%` : '0%'

                    return (
                        <div key={index} className='relative mb-2 mr-2 rounded-md bg-zinc-800 px-4 py-3'>
                            <div>
                                {item.alert.type === 'error' && <div className='text-red-500'>{item.alert.text}</div>}
                                {item.alert.type === 'info' && <div className='text-white'>{item.alert.text}</div>}
                                {item.alert.type === 'alert' && <div className='text-yellow-50'>{item.alert.text}</div>}
                                {item.alert.type === 'success' && (
                                    <div className='text-green-400'>{item.alert.text}</div>
                                )}
                            </div>
                            <div className='absolute bottom-0 left-0 mb-px h-[3px] bg-green-600' style={{ width }} />
                        </div>
                    )
                })}
            </div>
            {children}
        </AlertContext.Provider>
    )
}

export const useAlert = () => {
    const context = useContext(AlertContext)
    if (!context) {
        throw new Error('useAlert должен быть использован внутри AlertProvider')
    }
    return context.showAlert
}
