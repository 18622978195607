import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UserState {
    user: any | null
}

const initialState: UserState = {
    user: null
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<any>) {
            state.user = action.payload
            localStorage.setItem('user', JSON.stringify(action.payload))
            localStorage.setItem('isAuthorization', 'true')
        },
        logout(state) {
            state.user = null
            localStorage.removeItem('user')
            localStorage.removeItem('isAuthorization')
        },
        loadUserFromLocalStorage(state) {
            const user = localStorage.getItem('user')
            if (user) {
                state.user = JSON.parse(user)
            }
        }
    }
})

export const selectUser = (state: any) => state.user.user
export const { setUser, logout, loadUserFromLocalStorage } = userSlice.actions
export default userSlice.reducer
