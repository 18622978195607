import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import CustomSelectWithImage from '../../components/customselectwithimage'
import HoverOnElementDiv from '../../data/utilities/hoverOndiv'
import back from '../../images/header/back.png'
import back_white from '../../images/header/back_white.png'
import burger from '../../images/header/menu.svg'
import burger_white from '../../images/header/menu_white.svg'
import user from '../../images/header/user.svg'
import Route_paths from '../../routes/route_paths'
import { logout, selectUser } from '../../store/userSlice'

type Props = {}

const route_paths = new Route_paths()
const hoverOnElementDiv = new HoverOnElementDiv()
const Header = (props: Props) => {
    let user_G: any = useSelector(selectUser)
    console.log(user_G)
    const dispatch = useDispatch()
    const [isAuth, setIsAuth] = useState(localStorage.getItem('isAuthorization') === 'true')

    const options = [
        { value: route_paths.paths[3].path, label: isAuth ? 'Аккаунт' : '', image: '' },
        { value: route_paths.paths[4].path, label: isAuth ? 'Чат' : '', image: '' },
        {
            value: isAuth ? route_paths.paths[0].path : route_paths.paths[1].path,
            label: isAuth ? 'Выйти' : 'Войти',
            image: ''
        }
    ]

    const pages_numbers = [
        { id: 0, url: route_paths.paths[0].path },
        { id: 1, url: route_paths.paths[1].path },
        { id: 1, url: route_paths.paths[2].path },
        { id: 1, url: route_paths.paths[5].path }
    ]

    useEffect(() => {
        pages_numbers.forEach((pages_number) => {
            if (pages_number.url === window.location.pathname) setIsPage(pages_number.id)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (localStorage.getItem('isAuthorization') === 'true') setIsAuth(true)
        else setIsAuth(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem('isAuthorization')])

    const handleSelectChange = (option: any) => {
        if (option.label === 'Выйти') {
            dispatch(logout())
            window.location.href = option.value
        }
        window.location.href = option.value
    }

    const [isFocusedBurger, setIsFocusedBurger] = useState(false)
    const [isFocusedBack, setIsFocusedBack] = useState(false)
    const divRefBurger = useRef<HTMLDivElement | null>(null)
    const divRefBack = useRef<HTMLDivElement | null>(null)
    const [isPage, setIsPage] = useState(0)

    const handleMouseEnterBurger = () => {
        setIsFocusedBurger(true)
    }
    const handleMouseLeaveBurger = () => {
        setIsFocusedBurger(false)
    }
    const handleMouseEnterBack = () => {
        setIsFocusedBack(true)
    }
    const handleMouseLeaveBack = () => {
        setIsFocusedBack(false)
    }

    useEffect(() => {
        hoverOnElementDiv.hoverOnElementDiv(divRefBurger.current, handleMouseEnterBurger, handleMouseLeaveBurger)
    }, [divRefBurger])

    useEffect(() => {
        hoverOnElementDiv.hoverOnElementDiv(divRefBack.current, handleMouseEnterBack, handleMouseLeaveBack)
    }, [divRefBack])

    return (
        <div className='mx-5 h-full sm:mx-10'>
            <div className='flex size-full justify-between'>
                <div className='flex h-full'>
                    <div className='flex h-full'>
                        <div className='my-auto text-2xl font-black transition-colors hover:cursor-pointer hover:text-indigo-500 max-sm:hidden sm:text-4xl'>
                            <a href='/'>
                                <p style={{ marginBottom: '-18px' }}>Мой</p>
                                <p style={{ paddingLeft: '5px', marginTop: '-18px' }}>путь</p>
                            </a>
                        </div>
                        {isPage === 1 && (
                            <div className='my-auto mt-4 sm:ml-5' ref={divRefBack}>
                                <button onClick={() => window.history.back()}>
                                    <img src={isFocusedBack ? back : back_white} alt='Назад' />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className='flex h-full'>
                    <div className='my-auto flex'>
                        {isAuth ? (
                            <div>
                                <CustomSelectWithImage
                                    options={options}
                                    selectedOption={null}
                                    onSelect={handleSelectChange}
                                    selectImage={
                                        user_G && user_G.data && user_G.data.avatar ? user_G.data.avatar : user
                                    }
                                />
                            </div>
                        ) : (
                            <div ref={divRefBurger}>
                                <CustomSelectWithImage
                                    options={options}
                                    selectedOption={null}
                                    onSelect={handleSelectChange}
                                    selectImage={isFocusedBurger ? burger : burger_white}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
