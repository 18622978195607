class HoverOnElementDiv {
    hoverOnElementDiv = (divElement: HTMLDivElement | null, handleMouseEnter: any, handleMouseLeave: any) => {
        if (divElement) {
            divElement.addEventListener('mouseenter', handleMouseEnter)
            divElement.addEventListener('mouseleave', handleMouseLeave)
        }
        return () => {
            if (divElement) {
                divElement.removeEventListener('mouseenter', handleMouseEnter)
                divElement.removeEventListener('mouseleave', handleMouseLeave)
            }
        }
    }
}
export default HoverOnElementDiv
