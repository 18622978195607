import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

interface ApiError {
    data: {
        error: string
        message: string
        statusCode: number
    }
}

export const API = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.chat.vanityzone.ru/'
    }),
    endpoints: (build) => ({
        get: build.query<any, { url: string }>({
            query: ({ url }) => ({
                url,
                method: 'GET'
            })
        }),
        post: build.mutation<any, { url: string; body: any; method: string }>({
            query: ({ url, body, method }) => ({
                url,
                method,
                body
            })
        })
    })
})

export const { useGetQuery, useLazyGetQuery, usePostMutation } = API
export type { ApiError }
