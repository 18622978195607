import React from 'react'

import icon_telegram from '../../images/footer/Telegram_black.svg'
import icon_vk from '../../images/footer/VK_black.svg'
import icon_whatsapp from '../../images/footer/WhatsApp_black.svg'
import icon_youtube from '../../images/footer/YouTube_black.svg'

type Props = {}

const Fooret = (props: Props) => {
    return (
        <>
            <div className='my-auto flex justify-around max-sm:pt-5'>
                <div className='mr-4 w-[35px] max-sm:w-[30px]'>
                    <a href='/'>
                        <img src={icon_vk} alt='VK' />
                    </a>
                </div>
                <div className='mr-4 w-[35px] max-sm:w-[30px]'>
                    <a href='/'>
                        <img src={icon_telegram} alt='Telegram' />
                    </a>
                </div>
                <div className='mr-4 w-[35px] max-sm:w-[30px]'>
                    <a href='/'>
                        <img src={icon_youtube} alt='YouTube' />
                    </a>
                </div>
                <div className='w-[35px] max-sm:w-[30px]'>
                    <a href='/'>
                        <img src={icon_whatsapp} alt='WhatsApp' />
                    </a>
                </div>
            </div>
            <div className='my-auto max-sm:py-5 max-sm:text-sm'>
                Мой путь © {new Date().getFullYear()} Все права защищены.{' '}
            </div>
        </>
    )
}

export default Fooret
