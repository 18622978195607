import React, { useState } from 'react'

import mail from '../../images/forms/mail.svg'
import Route_paths from '../../routes/route_paths'

type Props = {}

const ForgotPassword = (props: Props) => {
    const route_paths = new Route_paths()
    const [isChecked, setIsChecked] = useState(false)
    return (
        <form className='flex min-h-[801px] grow items-center justify-center shadow-lg max-sm:mx-2 max-sm:h-screen'>
            <div className='my-auto h-[280px] w-[410px] rounded-lg bg-zinc-800'>
                <div className='mb-5 mt-6 flex items-center  justify-center'>
                    <div className='ml-2 flex items-center pb-2 text-2xl text-indigo-500 underline'>
                        Восстановление аккаутна
                    </div>
                </div>
                <div className='flex flex-col items-center justify-between'>
                    <div className='flex h-[50px] w-[306px] items-center justify-between rounded-lg bg-zinc-500'>
                        <input
                            placeholder='Почта'
                            className='w-[280px] bg-zinc-500 pl-2 outline-none'
                            type='text'
                            id='regemail'
                        />
                        <img className='mr-2' src={mail} alt='person'></img>
                    </div>
                </div>
                <div className='mt-6 flex items-center justify-center'>
                    <a
                        href={route_paths.paths[1].path}
                        className='text-sm underline transition-all hover:text-indigo-500'>
                        Вспомнили пароль
                    </a>
                </div>
                <button className='mt-3 h-[50px] w-[306px] rounded-lg bg-zinc-900 shadow-lg transition-all hover:bg-indigo-500'>
                    Отправить сообщение
                </button>
            </div>
        </form>
    )
}

export default ForgotPassword
