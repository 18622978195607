import React, { useState, useEffect, useRef } from 'react'

interface Option {
    value: string
    label: string
    image?: string
}

interface Props {
    options: Option[]
    selectedOption: Option | null
    onSelect: (option: Option) => void
    selectImage?: string
}

const CustomSelectWithImage: React.FC<Props> = ({ options, selectedOption, onSelect, selectImage }) => {
    const [isOpen, setIsOpen] = useState(false)
    const selectRef = useRef<HTMLDivElement>(null) // Создаем реф для отслеживания клика вне компонента

    const handleClick = () => {
        setIsOpen(!isOpen)
    }

    const handleOptionClick = (option: Option) => {
        onSelect(option)
        setIsOpen(false)
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside) // Очистка события при размонтировании
        }
    }, [])

    return (
        <div ref={selectRef} className='relative flex cursor-pointer items-center rounded-md' onClick={handleClick}>
            {selectImage && (
                <div
                    className='mr-3 size-12 rounded-full bg-cover bg-center'
                    style={{ backgroundImage: `url(${selectImage})` }}
                />
            )}
            {isOpen && (
                <div className='absolute -right-5 top-full z-10 mt-4 w-32 rounded-md bg-zinc-800 shadow-md'>
                    {options.map(
                        (option) =>
                            option.label !== '' && (
                                <div
                                    key={option.value}
                                    className={`flex cursor-pointer items-center px-5 py-2 transition-all hover:bg-gray-100 hover:text-black ${selectedOption && selectedOption.value === option.value ? 'bg-gray-200' : ''}`}
                                    onClick={() => handleOptionClick(option)}>
                                    {option.image && (
                                        <div
                                            className='mr-3 size-8 rounded-full bg-cover bg-center'
                                            style={{ backgroundImage: `url(${option.image})` }}
                                        />
                                    )}
                                    <span>{option.label}</span>
                                </div>
                            )
                    )}
                </div>
            )}
        </div>
    )
}

export default CustomSelectWithImage
