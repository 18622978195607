import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

import Contact from '../../components/help/Chat'
import icon_search from '../../images/header/search.svg'
import icon_edit from '../../images/help/chats/edit.svg'
// import icon_edit_white from '../../images/help/chats/edit_white.svg'
import icon_team from '../../images/help/chats/team.svg'
import { useLazyGetQuery } from '../../store/API'
import { selectUser } from '../../store/userSlice'
// import icon_team_white from '../../images/help/chats/team_white.svg'

const Chats = ({ onChatSelect }: { onChatSelect: (chatId: string | null) => void }) => {
    const [isFocused, setIsFocused] = useState(false)
    const [search, setSearch] = useState('')
    const [fetchChats, { isLoading: isLoadingAllChats, data: allChats }] = useLazyGetQuery()

    const user_id: any = useSelector(selectUser)

    const fetchChats_G = async () => {
        const allChats_G = await fetchChats({
            url: `chats/user/${user_id.data._id}`
        })
        allChats_G.data.forEach((chat: any) => {
            if (chat.chat._id === localStorage.getItem('currentChat')) onChatSelect(chat)
        })
    }

    useEffect(() => {
        if (user_id) fetchChats_G()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_id])

    const filteredChats = allChats?.filter((chat: any) => {
        return chat.chat.name.toLowerCase().includes(search.toLowerCase())
    })

    const clickOnChat = async (chat: any) => {
        setSearch('')
        if (chat.chat._id !== localStorage.getItem('currentChat')) {
            localStorage.setItem('currentChat', chat.chat._id)
            onChatSelect(chat)
        }
    }

    return (
        <div className='relative w-[350px] bg-zinc-700'>
            <div className='p-3'>
                <div className='mx-1 mt-1 flex justify-between'>
                    <div className='flex h-full'>
                        <img className='w-[30px]' src={icon_team} alt='team' />
                        <span className='my-auto ml-3 text-nowrap'>Список диалогов</span>
                    </div>
                    <div className='w-[28px]'>
                        <button>
                            <img src={icon_edit} alt='edit' />
                        </button>
                    </div>
                </div>
                <div className='w-full'>
                    <div
                        className={`mb-2 mt-7 flex rounded-lg bg-zinc-600 shadow-lg transition-all ${isFocused ? 'shadow-indigo-500' : ''} hover:cursor-pointer hover:shadow-indigo-500`}>
                        <img className='ml-1' src={icon_search} alt='лупа' />
                        <input
                            className='m-1 h-[32px] w-full bg-zinc-600 outline-none'
                            type='text'
                            placeholder='Поиск по названию'
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        {search && search.length > 0 && (
                            <button className='mr-2' onClick={() => setSearch('')}>
                                ✖️
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <div>
                {isLoadingAllChats && <p className='text-center'>Загрузка...</p>}
                {filteredChats?.map((element: any, index: number) => (
                    <div
                        key={index}
                        className={`z-0 w-full transition-colors hover:bg-zinc-600 ${localStorage.getItem('currentChat') === element.chat._id && 'bg-zinc-600'}`}>
                        <button className='w-full' onClick={() => clickOnChat(element)}>
                            <Contact avatar={element.chat.avatar} name={element.chat.name} online={false} />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Chats
