import React from 'react'

import { Navigate } from 'react-router-dom'

interface IPrivateRouteProps {
  children: JSX.Element
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ children }) => {
  const isAuthorized = localStorage.getItem('isAuthorization') === 'true'
  return isAuthorized ? children : <Navigate to='/authorization' />
}

export default PrivateRoute
