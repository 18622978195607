import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import Footer from './components/layuots/footer'
import Header from './components/layuots/header'
import { AlertProvider } from './components/miniComponents/Alert'
import RoutesApp from './routes/routes'
import { loadUserFromLocalStorage } from './store/userSlice'

export default function App() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(loadUserFromLocalStorage())
    }, [dispatch])
    return (
        <AlertProvider>
            <div className='flex min-h-screen flex-col'>
                <header className='h-[80px] bg-zinc-800 p-0'>
                    <Header />
                </header>
                <main className='flex grow flex-col items-center justify-center bg-zinc-700'>
                    <div className='prose w-full max-w-full'>
                        <RoutesApp />
                    </div>
                </main>
                <footer className='mt-auto flex justify-between bg-zinc-800 text-white max-sm:block sm:h-[90px] sm:px-32'>
                    <Footer />
                </footer>
            </div>
        </AlertProvider>
    )
}
