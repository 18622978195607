import React from 'react'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Authorization from '../components/forms/authorization'
import ForgotPassword from '../components/forms/forgotpassword'
import Registration from '../components/forms/registration'
import Account from '../pages/account'
import Help from '../pages/help'
import Main from '../pages/main'

import PrivateRoute from './privateRoute'
import PublicRoute from './publicRoute'
import Route_paths from './route_paths'

// eslint-disable-next-line prettier/prettier
interface IRoutesAppProps { }

const RoutesApp: React.FC<IRoutesAppProps> = (props) => {
    const route_paths = new Route_paths()
    return (
        <Router>
            <Routes>
                <Route path={route_paths.paths[0].path} Component={Main} />
                <Route
                    path={route_paths.paths[1].path}
                    element={
                        <PublicRoute>
                            <Authorization />
                        </PublicRoute>
                    }
                />
                <Route
                    path={route_paths.paths[2].path}
                    element={
                        <PublicRoute>
                            <Registration />
                        </PublicRoute>
                    }
                />
                <Route
                    path={route_paths.paths[3].path}
                    element={
                        <PrivateRoute>
                            <Account />
                        </PrivateRoute>
                    }
                />
                <Route path={route_paths.paths[4].path} Component={Help} />
                <Route
                    path={route_paths.paths[5].path}
                    element={
                        <PublicRoute>
                            <ForgotPassword />
                        </PublicRoute>
                    }
                />
            </Routes>
        </Router>
    )
}

export default RoutesApp
