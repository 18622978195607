import React from 'react'

import avatar_user from '../../images/help/message/user.png'

type Props = {
    isChecked?: boolean
    avatar?: string
    name: string
    lastMessage?: {
        text: string
        data: string
    }
    unread?: number
    online?: boolean
}

const Contact = (props: Props) => {
    let lenghtLastMennsage = 20
    return (
        <div className={`flex w-full justify-between ${props.isChecked ? 'bg-zinc-600' : ''}  px-3 py-2`}>
            <div className='flex'>
                <div className='mr-3 w-[48px]'>
                    <img
                        className='rounded-xl'
                        src={props.avatar && props.avatar ? props.avatar : avatar_user}
                        alt='user'
                    />
                </div>
                <div className='text-left'>
                    <div className='flex h-fit font-bold'>
                        <div>{props.name && props.name.length > 15 ? props.name.slice(0, 15) + '...' : props.name}</div>
                        <div className='ml-1.5 mt-0.5 h-full text-sm'>
                            <div className={`status-dot ${props.online ? 'bg-green-400' : ''}`}></div>
                        </div>
                    </div>
                    <div className='text-ellipsis text-nowrap text-sm font-light'>
                        {props.lastMessage && props.lastMessage.text.length > lenghtLastMennsage
                            ? props.lastMessage.text.slice(0, lenghtLastMennsage) + '...'
                            : props.lastMessage && props.lastMessage.text}
                    </div>
                </div>
            </div>
            <div className='text-right text-sm'>
                <div>{props.lastMessage && props.lastMessage.data}</div>
                <div className='flex justify-end'>
                    {props.unread && props.unread !== 0 ? (
                        <div
                            style={{ paddingTop: ' 0.17rem', paddingBottom: ' 0.17rem', marginTop: '0.1rem' }}
                            className='w-fit rounded-xl bg-red-400 px-2'>
                            {props.unread}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default Contact
