import * as React from 'react'

interface IComponentProps {
    imagee: any
}

const Component: React.FunctionComponent<IComponentProps> = (props) => {
    return (
        <div className='text-black'>
            <img src={props.imagee} alt='' />
        </div>
    )
}

export default Component
