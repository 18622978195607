import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Validates from '../../data/utilities/validates'
import lock from '../../images/forms/Lock.svg'
import person from '../../images/forms/person.svg'
import user_indigo from '../../images/header/user-indigo.svg'
import Route_paths from '../../routes/route_paths'
import { ApiError, usePostMutation } from '../../store/API'
import { setUser } from '../../store/userSlice'
import { useAlert } from '../miniComponents/Alert'

import GeneralForm from './GeneralForm'

type Props = {}

const route_paths = new Route_paths()
const validates = new Validates()
const Authorization = (props: Props) => {
    const [isChecked, setIsChecked] = useState(false)
    const showAlert = useAlert()
    const navigate = useNavigate()
    const [fetchAuth, { isLoading }] = usePostMutation()
    const dispatch = useDispatch()
    const fun_fetchAuth = async (login: string, password: string) => {
        try {
            const authUser: any = await fetchAuth({
                url: 'users/getForAuth',
                method: 'POST',
                body: {
                    login: login,
                    password: password
                }
            })
            if (authUser.error) {
                const apiError = authUser.error as ApiError
                if (apiError.data.statusCode === 404) {
                    showAlert({ type: 'error', text: `Пользователь не найден` })
                }
            } else {
                dispatch(setUser(authUser))
                showAlert({ type: 'success', text: 'Успешная авторизация!' })
                setTimeout(() => {
                    navigate('/account')
                    window.location.reload()
                }, 750)
            }
        } catch (error) {
            const apiError = error as ApiError
            showAlert({ type: 'error', text: `Произошла ошибка, попробуйте позже...` })
            console.error('Произошла ошибка во время аутентификации:', apiError.data)
        }
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const login = document.getElementById('login') as HTMLInputElement
        const password = document.getElementById('password') as HTMLInputElement
        const checkFormsInput = (): string | boolean => {
            if (login) {
                if (validates.checkLogin(login.value) === true) {
                    if (password) {
                        if (validates.checkPassword(password.value) === true) {
                            fun_fetchAuth(login.value, password.value)
                            return true
                        }
                        return validates.checkPassword(password.value)
                    }
                    return false
                }
                return validates.checkLogin(login.value)
            }
            return false
        }
        const result = checkFormsInput()
        if (result !== true) showAlert({ type: 'error', text: checkFormsInput().toString() })
    }

    return (
        <GeneralForm onSubmitText={isLoading ? 'Загрузка...' : 'Войти'} onSubmit={handleSubmit}>
            <div className='mb-5 mt-4 flex items-center  justify-center'>
                <img src={user_indigo} alt='user'></img>
                <div className='ml-2 flex items-center pb-2 text-2xl text-indigo-500 underline'>Авторизация</div>
            </div>
            <div className='flex flex-col items-center justify-between'>
                <div className='flex h-[50px] w-[306px] items-center justify-between rounded-lg bg-zinc-500'>
                    <input
                        placeholder='Логин'
                        className='w-[280px] bg-zinc-500 pl-2 outline-none'
                        type='text'
                        id='login'
                    />
                    <img className='mr-2' src={person} alt='person'></img>
                </div>
                <div className='mt-4 flex h-[50px] w-[306px] items-center justify-between rounded-lg bg-zinc-500 shadow-lg'>
                    <input
                        placeholder='Пароль'
                        className='w-[280px] bg-zinc-500 pl-2 outline-none'
                        type={isChecked ? 'text' : 'password'}
                        id='password'
                    />
                    <img className='mr-2' src={lock} alt='lock'></img>
                </div>
            </div>
            <div className='mt-4 flex items-center justify-center'>
                <label className='mr-10 flex'>
                    <input onClick={() => setIsChecked(!isChecked)} className='mr-1 h-[20px]' type='checkbox'></input>
                    <p
                        className={`text-sm underline ${isChecked ? 'text-indigo-500' : ''} transition-all hover:cursor-pointer hover:text-indigo-500`}>
                        Показать пароль
                    </p>
                </label>
                <a href={route_paths.paths[5].path} className='text-sm underline transition-all hover:text-indigo-500'>
                    Забыли пароль?
                </a>
            </div>
            <div className='flex justify-center'>
                <a
                    className='mt-5 block size-full h-[50px] w-[306px] rounded-lg bg-green-600 pt-3 text-center shadow-lg transition-all hover:bg-zinc-500'
                    href={route_paths.paths[2].path}>
                    Зарегистрироваться
                </a>
            </div>
        </GeneralForm>
    )
}

export default Authorization
