import React, { useEffect, useRef, useState } from 'react'

import { clickOptions } from '@testing-library/user-event/dist/click'

import HoverOnElementDiv from '../../data/utilities/hoverOndiv'
import icon_pin from '../../images/help/enterMessage/pin.png'
import icon_pin_white from '../../images/help/enterMessage/pin_white.png'
import icon_send from '../../images/help/enterMessage/send.png'
import icon_send_white from '../../images/help/enterMessage/send_white.png'
import icon_smiles from '../../images/help/enterMessage/smayls.jpg'
import icon_smiles_white from '../../images/help/enterMessage/smayls_white.png'
import icon_voice from '../../images/help/enterMessage/voice.png'
import icon_voice_white from '../../images/help/enterMessage/voice_white.png'

type Props = {
    value: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onClick: any
}

const hoverOnElementDiv = new HoverOnElementDiv()
const EnterMessage = (props: Props) => {
    const [isTyping, setIsTyping] = useState(true)
    const [isFocusedPin, setIsFocusedPin] = useState(false)
    const [isFocusedSmiles, setIsFocusedSmiles] = useState(false)
    const [isFocusedVoise, setIsFocusedVoise] = useState(false)
    const [isFocusedSend, setIsFocusedSend] = useState(false)
    const divRefPin = useRef<HTMLDivElement | null>(null)
    const divRefSmiles = useRef<HTMLDivElement | null>(null)
    const divRefVoice = useRef<HTMLDivElement | null>(null)
    const divRefSend = useRef<HTMLDivElement | null>(null)

    const handleMouseEnterPin = () => {
        setIsFocusedPin(true)
    }
    const handleMouseLeavePin = () => {
        setIsFocusedPin(false)
    }
    const handleMouseEnterSmiles = () => {
        setIsFocusedSmiles(true)
    }
    const handleMouseLeaveSmiles = () => {
        setIsFocusedSmiles(false)
    }
    const handleMouseEnterVoise = () => {
        setIsFocusedVoise(true)
    }
    const handleMouseLeaveVoise = () => {
        setIsFocusedVoise(false)
    }
    const handleMouseEnterSend = () => {
        setIsFocusedSend(true)
    }
    const handleMouseLeaveSend = () => {
        setIsFocusedSend(false)
    }
    useEffect(() => {
        hoverOnElementDiv.hoverOnElementDiv(divRefPin.current, handleMouseEnterPin, handleMouseLeavePin)
    }, [divRefPin])

    useEffect(() => {
        hoverOnElementDiv.hoverOnElementDiv(divRefSmiles.current, handleMouseEnterSmiles, handleMouseLeaveSmiles)
    }, [divRefSmiles])

    useEffect(() => {
        hoverOnElementDiv.hoverOnElementDiv(divRefVoice.current, handleMouseEnterVoise, handleMouseLeaveVoise)
    }, [divRefVoice])

    useEffect(() => {
        hoverOnElementDiv.hoverOnElementDiv(divRefSend.current, handleMouseEnterSend, handleMouseLeaveSend)
    }, [divRefSend])

    const handleIsTyping = () => {
        const inputElement = document.getElementById('enter_message') as HTMLInputElement | null
        if (inputElement) {
            if (inputElement.value === '') setIsTyping(false)
            else if (inputElement.value !== '') setIsTyping(true)
        }
    }

    return (
        <div className='flex h-[55px] bg-zinc-700 px-2'>
            <div className='mx-1 my-auto hover:cursor-pointer'>
                <div ref={divRefPin}>
                    <img className='w-[40px]' src={!isFocusedPin ? icon_pin_white : icon_pin} alt='123' />
                </div>
            </div>
            <div className='mx-2 my-auto flex w-full rounded-lg p-2'>
                <input
                    className='my-auto h-10 w-full bg-zinc-700 focus:outline-none'
                    type='text'
                    name='enter_message'
                    id='enter_message'
                    placeholder='Напишите собщение...'
                    onChange={props.onChange}
                    value={props.value}
                    autoComplete='off'
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            props.onClick()
                        }
                    }}
                />
                <div className='my-auto hover:cursor-pointer' ref={divRefSmiles}>
                    <img className='w-[30px]' src={!isFocusedSmiles ? icon_smiles_white : icon_smiles} alt='Стикеры' />
                </div>
            </div>
            {isTyping ? (
                <button>
                    <div className='mx-4 my-auto hover:cursor-pointer' ref={divRefSend}>
                        <img
                            onClick={props.onClick}
                            className='w-[45px]'
                            src={!isFocusedSend ? icon_send_white : icon_send}
                            alt='Отправить'
                        />
                    </div>
                </button>
            ) : (
                <div className='mx-4 my-auto hover:cursor-pointer' ref={divRefVoice}>
                    <img className='w-[30px]' src={!isFocusedVoise ? icon_voice_white : icon_voice} alt='Аудио' />
                </div>
            )}
        </div>
    )
}

export default EnterMessage
