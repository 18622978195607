import React, { useState, useCallback, useEffect, useRef } from 'react'

interface ContextMenuProps {
    x: number
    y: number
    onClose: () => void
    onEdit: () => void
    onDelete: () => void
}

function MenuContext({ x, y, onClose, onEdit, onDelete }: ContextMenuProps) {
    const titles = [
        { title: 'Редактировать', onClick: onEdit },
        { title: 'Удалить', onClick: onDelete }
    ]

    const [isVisible, setIsVisible] = useState(true)
    const menuRef = useRef<HTMLDivElement>(null)

    const hideMenu = useCallback(() => {
        onClose()
        setIsVisible(false)
    }, [onClose])

    const handleClickOutside = useCallback(
        (event: any) => {
            if (event.target instanceof Node && !event.target.contains(menuRef.current)) {
                hideMenu()
            }
        },
        [hideMenu]
    )

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [handleClickOutside])

    if (!isVisible) return null

    return (
        <div
            id='context-menu'
            className='absolute rounded-sm bg-zinc-500 px-2 py-1'
            style={{ left: `${x}px`, top: `${y}px` }}>
            {titles.map((item, index) => (
                <div key={index}>
                    <button onClick={item.onClick}>{item.title}</button>
                </div>
            ))}
        </div>
    )
}

export default MenuContext
