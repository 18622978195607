import React from 'react'

import Component from '../components/component'
import css from '../images/main/css.svg'
import html from '../images/main/html5.svg'
import js from '../images/main/js.svg'
import react from '../images/main/react.svg'

type Props = {}

const Main = (props: Props) => {
    return (
        <main className='flex grow max-[420px]:h-screen'>
            <div className='container mx-auto grid h-full grid-cols-1 gap-2 px-5 pt-4 max-lg:mx-auto max-[420px]:h-fit xl:my-auto xl:grid-cols-2'>
                <a href='/' className='mx-auto scale-100 transition hover:scale-95'>
                    <Component imagee={html} />
                </a>
                <a href='/' className='mx-auto scale-100 transition hover:scale-95'>
                    <Component imagee={css} />
                </a>
                <a href='/' className='mx-auto scale-100 transition hover:scale-95'>
                    <Component imagee={js} />
                </a>
                <a href='/' className='mx-auto scale-100 transition hover:scale-95'>
                    <Component imagee={react} />
                </a>
            </div>
        </main>
    )
}

export default Main
