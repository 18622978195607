import * as React from 'react'

import { useForm, SubmitHandler } from 'react-hook-form'

interface IGeneralFormProps {
    children: React.ReactNode
    onSubmit: React.FormEventHandler<HTMLFormElement> | undefined
    onSubmitText: string
}

const GeneralForm: React.FC<IGeneralFormProps> = (props) => {
    return (
        <form
            onSubmit={props.onSubmit}
            className='flex min-h-[801px] grow items-center justify-center shadow-lg max-sm:mx-2 max-sm:h-screen'>
            <div className='my-auto w-[400px] rounded-lg bg-zinc-800'>
                <div className='flex w-full flex-col justify-center'>
                    {props.children}
                    <button className='mx-auto mt-3 h-[50px] w-[306px] rounded-lg bg-zinc-900 text-center shadow-lg transition-all hover:bg-indigo-500'>
                        {props.onSubmitText}
                    </button>
                    <br />
                </div>
            </div>
        </form>
    )
}

export default GeneralForm
