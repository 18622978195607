import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useSelector } from 'react-redux'
import io, { Socket } from 'socket.io-client'

import Chats from '../components/help/Chats'
import EnterMessage from '../components/help/EnterMessage'
import Message from '../components/help/Message'
import { useAlert } from '../components/miniComponents/Alert'
import MenuContext from '../components/miniComponents/MenuContext'
import icon_more from '../images/help/chats/more.svg'
import icon_more_white from '../images/help/chats/more_white.svg'
import { ApiError, useLazyGetQuery, usePostMutation } from '../store/API'
import { selectUser } from '../store/userSlice'

type Props = {}

const Help = (props: Props) => {
    const showAlert = useAlert()
    const [messages, setMessages] = useState<any[]>([])
    const [newMessage, setNewMessage] = useState('')
    const [isHaveMessages, setIsHaveMessages] = useState(true)
    const socketRef = useRef<Socket | null>(null)
    const chatId = localStorage.getItem('currentChat')

    const [focusedMessage, setFocusedMessage] = useState('')

    const online = true
    let user: any = useSelector(selectUser)

    const [selectedChat, setSelectedChat] = useState({
        chat: {
            _id: '',
            name: '',
            avatar: '',
            creator_id: '',
            participant_ids: [''],
            created_at: '',
            __v: 0
        },
        creator: {
            _id: '',
            login: '',
            avatar: ''
        },
        participants: [
            {
                _id: '',
                login: '',
                avatar: ''
            }
        ]
    })
    const [fetchMessagesForChat, { isLoading: isLoadingMessages, data: allMessages }] = useLazyGetQuery()
    const [fetchDeleteMessage] = usePostMutation()
    const handleChatSelect = async (chat: any) => {
        if (chat) {
            setSelectedChat(chat)
            const allMessages = await fetchMessagesForChat({
                url: `messages/chat/${chat.chat._id}`
            })
            if (allMessages.data.length === 0) setIsHaveMessages(false)
            else setIsHaveMessages(true)
        }
    }

    useEffect(() => {
        socketRef.current = io('ws://api.chat.vanityzone.ru')

        socketRef.current.on('connect', () => {
            console.log('Websocket connected!')
            socketRef.current?.emit('joinChat', chatId)
        })

        socketRef.current.on('disconnect', () => {
            console.error('Websocket error connect...')
        })

        socketRef.current.on('newMessage', (message: any) => {
            setMessages((prevMessages) => [...prevMessages, message])
        })
        setMessages([])
        setNewMessage('')
        return () => {
            socketRef.current?.disconnect()
        }
    }, [chatId])

    const handleSendMessage = async () => {
        if (newMessage.trim() !== '') {
            socketRef.current?.emit('sendMessage', {
                chatId,
                userId: user.data._id,
                login: user.data.login,
                avatar: user.data.avatar,
                text: newMessage
            })
            setNewMessage('')
        }
    }

    const messagesEndRef = useRef<HTMLDivElement>(null)
    const [showScrollButton, setShowScrollButton] = useState(false)

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    useEffect(() => {
        scrollToBottom()
    }, [allMessages, messages])

    const scrollContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const currentRef = scrollContainerRef.current
        if (!currentRef) return
        const handleScroll = () => {
            if (currentRef.scrollHeight - 900 > currentRef.scrollTop) setShowScrollButton(true)
            else setShowScrollButton(false)
        }
        currentRef.addEventListener('scroll', handleScroll)
        return () => {
            currentRef.removeEventListener('scroll', handleScroll)
        }
    }, [scrollContainerRef])

    const handleContexMenu = (event: any, array: any) => {
        openContextMenu(event)
        let divElement = event.target
        if (event.target.id !== 'parent' && event.target.id !== 'parent2') {
            divElement = event.target.parentNode
        }
        if (event.target.id === 'parent2') {
            divElement = event.target.children[0]
        }
        setFocusedMessage(divElement.children[0].id)
    }

    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 })

    const openContextMenu = useCallback((event: any) => {
        event.preventDefault()
        setContextMenuPosition({ x: event.clientX, y: event.clientY })
        setIsContextMenuOpen(true)
    }, [])

    const closeContextMenu = useCallback(() => {
        setContextMenuPosition({ x: 0, y: 0 })
        setIsContextMenuOpen(false)
    }, [])

    const menuContext_onDelete = async () => {
        try {
            await fetchDeleteMessage({
                url: `messages/${focusedMessage}`,
                method: 'DELETE',
                body: {}
            }).unwrap()
            document
                .getElementById(focusedMessage)
                ?.parentNode?.parentNode?.parentNode?.parentElement?.classList.add('hidden')
            showAlert({ type: 'success', text: 'Сообщение успешно удалено!' })
        } catch (error) {
            const apiError = error as ApiError
            showAlert({ type: 'error', text: `Произошла ошибка, попробуйте позже...` })
            console.error('Error posting data:', apiError.data.message)
        }
    }

    const menuContext_onEdit = () => {
        console.log('123')
    }

    return (
        <section className='w-4/5'>
            <div className='mx-auto flex w-[1140px] bg-zinc-600 text-left shadow-lg'>
                <Chats onChatSelect={handleChatSelect} />
                <div className='w-full'>
                    <div className='flex justify-between p-3 shadow-2xl'>
                        <div className='mr-7'></div>
                        <div className='text-center'>
                            <div className='font-bold'>{selectedChat.chat.name}</div>
                            <div className='flex justify-center'>
                                {selectedChat.chat.name && (
                                    <div className='text-sm'>
                                        <span
                                            className={`status-dot ${online ? 'bg-green-400' : 'bg-gray-400'}`}></span>
                                        {online ? 'online' : 'offline'}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <button>
                                <img className='w-[35px]' src={icon_more_white} alt='more' />
                            </button>
                        </div>
                    </div>
                    <div>
                        <div ref={scrollContainerRef} className='h-[669px] overflow-y-scroll p-3'>
                            {allMessages &&
                                allMessages !== null &&
                                allMessages?.map((element: any, index: number) => {
                                    let participant = selectedChat.participants.find(
                                        (participant) => element.user_id === participant._id
                                    )
                                    if (!participant) participant = selectedChat.creator
                                    if (participant) {
                                        const created_at = new Date(element.created_at)
                                        const created_at_formatted = created_at.toLocaleTimeString('en-GB', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        })
                                        return (
                                            <div key={index}>
                                                <Message
                                                    onContextMenu={(e) => {
                                                        e.preventDefault()
                                                        handleContexMenu(e, allMessages)
                                                    }}
                                                    id={element._id}
                                                    user={{
                                                        name: participant.login,
                                                        avatar: participant.avatar
                                                    }}
                                                    message={{
                                                        text: element.text,
                                                        date: created_at_formatted
                                                    }}
                                                    toOrFrom={user.data._id === participant._id}
                                                />
                                            </div>
                                        )
                                    }
                                    return null
                                })}
                            {messages &&
                                messages.map((message, index) => {
                                    let sender: {
                                        avatar: string
                                        login: string
                                        _id: string
                                    } = { avatar: '', login: 'Без имени', _id: '' }
                                    if (selectedChat.creator._id === message.user_id) {
                                        sender = selectedChat.creator
                                    } else {
                                        selectedChat.participants.forEach((participant) => {
                                            if (participant._id === message.user_id) {
                                                sender = participant
                                            }
                                        })
                                    }
                                    let now = new Date()
                                    let hours = now.getHours().toString().padStart(2, '0')
                                    let minutes = now.getMinutes().toString().padStart(2, '0')
                                    let currentTime = `${hours}:${minutes}`
                                    return (
                                        <div key={index}>
                                            <Message
                                                onContextMenu={(e) => {
                                                    e.preventDefault()
                                                    handleContexMenu(e, messages)
                                                }}
                                                id={message._id}
                                                user={{
                                                    name: sender.login,
                                                    avatar: sender.avatar
                                                }}
                                                message={{
                                                    text: message.text,
                                                    date: currentTime
                                                }}
                                                toOrFrom={user.data._id === message.user_id}
                                            />
                                        </div>
                                    )
                                })}

                            {!localStorage.getItem('currentChat') && (
                                <div className='flex h-full items-center justify-center'>Не выбран чат...</div>
                            )}
                            {!isHaveMessages && messages.length === 0 && (
                                <div className='flex h-full items-center justify-center'>
                                    Сообщений нет, напишите что нибдь...
                                </div>
                            )}
                            {isLoadingMessages && (
                                <div className='flex h-full items-center justify-center'>Загрузка сообщений...</div>
                            )}
                            <div className='shadow-2xl'>
                                <br />
                            </div>

                            <div ref={messagesEndRef} />
                        </div>
                        {showScrollButton && (
                            <div className='fixed -mt-14 ml-96 opacity-65'>
                                <button
                                    className='rounded-full bg-zinc-500 px-7 text-3xl transition-colors hover:bg-indigo-500 hover:text-black'
                                    onClick={scrollToBottom}>
                                    ▼
                                </button>
                            </div>
                        )}
                        <EnterMessage
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onClick={handleSendMessage}
                        />
                        {isContextMenuOpen && (
                            <MenuContext
                                x={contextMenuPosition.x}
                                y={contextMenuPosition.y}
                                onClose={closeContextMenu}
                                onDelete={menuContext_onDelete}
                                onEdit={menuContext_onEdit}
                            />
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Help
