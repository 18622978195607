import React from 'react'

import { Navigate } from 'react-router-dom'

interface IPublicRouteProps {
    children: JSX.Element
}

const PublicRoute: React.FC<IPublicRouteProps> = ({ children }) => {
    const isAuthorized = localStorage.getItem('isAuthorization') !== 'true'
    return isAuthorized ? children : <Navigate to='/account' />
}

export default PublicRoute
