import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import Validates from '../../data/utilities/validates'
import lock from '../../images/forms/Lock.svg'
import mail from '../../images/forms/mail.svg'
import person from '../../images/forms/person.svg'
import user_indigo from '../../images/header/user-indigo.svg'
import Route_paths from '../../routes/route_paths'
import { ApiError, usePostMutation } from '../../store/API'
import { useAlert } from '../miniComponents/Alert'

import GeneralForm from './GeneralForm'

type Props = {}

const route_paths = new Route_paths()
const validates = new Validates()
const Registration = (props: Props) => {
    const navigate = useNavigate()
    const [isChecked, setIsChecked] = useState(false)
    const showAlert = useAlert()

    const [postData, { isLoading }] = usePostMutation()
    const createUser = async (login: string, password: string, email: string) => {
        try {
            await postData({
                url: 'users',
                method: 'POST',
                body: {
                    login: login,
                    password: password,
                    email: email
                }
            }).unwrap()
            showAlert({ type: 'success', text: 'Пользователь успешно создан!' })
            setTimeout(() => {
                navigate('/authorization')
            }, 1000)
        } catch (error) {
            console.error('Error posting data:', error)
            const apiError = error as ApiError
            showAlert({ type: 'error', text: `Произошла ошибка: ${apiError.data.message}` })
        }
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const login = document.getElementById('reglogin') as HTMLInputElement
        const password = document.getElementById('regpassword') as HTMLInputElement
        const rePassword = document.getElementById('regrepassword') as HTMLInputElement
        const email = document.getElementById('regemail') as HTMLInputElement
        const checkFormsInput = (): string | boolean => {
            if (login && validates.checkLogin(login.value) === true) {
                if (email && validates.checkEmail(email.value) === true) {
                    if (rePassword && password && rePassword.value === password.value) {
                        if (validates.checkPassword(password.value) === true) {
                            createUser(login.value, password.value, email.value)
                            return true
                        } else return validates.checkPassword(password.value)
                    } else return 'Пароли не совпадают'
                } else return validates.checkEmail(email.value)
            } else return validates.checkLogin(login.value)
        }
        const result = checkFormsInput()
        if (result !== true) showAlert({ type: 'error', text: checkFormsInput().toString() })
    }
    return (
        <GeneralForm onSubmitText={isLoading ? 'Загрузка...' : 'Зарегистрироваться'} onSubmit={handleSubmit}>
            <div className='my-5 flex items-center justify-center'>
                <img src={user_indigo} alt='user'></img>
                <div className='ml-2 flex items-center pb-2 text-2xl text-indigo-500 underline'>Регистрация</div>
            </div>
            <div className='flex flex-col items-center justify-between'>
                <div className='flex h-[50px] w-[306px] items-center justify-between rounded-lg bg-zinc-500'>
                    <input
                        placeholder='Логин'
                        className='w-[280px] bg-zinc-500 pl-2 outline-none'
                        type='text'
                        id='reglogin'
                    />
                    <img className='mr-2' src={person} alt='person'></img>
                </div>
                <div className='mt-4 flex h-[50px] w-[306px] items-center justify-between rounded-lg bg-zinc-500'>
                    <input
                        placeholder='Почта'
                        className='w-[280px] bg-zinc-500 pl-2 outline-none'
                        type='text'
                        id='regemail'
                    />
                    <img className='mr-2' src={mail} alt='person'></img>
                </div>
                <div className='mt-4 flex h-[50px] w-[306px] items-center justify-between rounded-lg bg-zinc-500 shadow-lg'>
                    <input
                        placeholder='Пароль'
                        className='w-[280px] bg-zinc-500 pl-2 outline-none'
                        type={isChecked ? 'text' : 'password'}
                        id='regpassword'
                    />
                    <img className='mr-2' src={lock} alt='lock'></img>
                </div>
                <div className='mt-4 flex h-[50px] w-[306px] items-center justify-between rounded-lg bg-zinc-500 shadow-lg'>
                    <input
                        placeholder='Повторить пароль'
                        className='w-[280px] bg-zinc-500 pl-2 outline-none'
                        type={isChecked ? 'text' : 'password'}
                        id='regrepassword'
                    />
                    <img className='mr-2' src={lock} alt='lock'></img>
                </div>
            </div>
            <div className='mt-4 flex items-center justify-center'>
                <label className='mr-10 flex'>
                    <input onClick={() => setIsChecked(!isChecked)} className='mr-1 h-[20px]' type='checkbox'></input>
                    <p
                        className={`text-sm underline ${isChecked ? 'text-indigo-500' : ''} transition-all hover:cursor-pointer hover:text-indigo-500`}>
                        Показать пароль
                    </p>
                </label>
                <a href={route_paths.paths[1].path} className='text-sm underline transition-all hover:text-indigo-500'>
                    Есть аккаунт?
                </a>
            </div>
        </GeneralForm>
    )
}

export default Registration
